<template>
  <obchodni-rozpracovanost-tab :show-buttons="false" :show-filters="false" />
</template>

<script>
import ObchodniRozpracovanostTab from "@/views/DohledNadOz/ObchodniRozpracovanostTab.vue";


export default {
  components: {
    ObchodniRozpracovanostTab
  },
  data() {
    return {}
  },
  async mounted() {
  },
};
</script>

<style>
 .lh-2 {
   line-height: 2;
 }
</style>
